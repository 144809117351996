import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {getAuth, getAuthHeaders} from "../admin/auth";
import {Button, DatePicker, Form, Image, Input, message, Modal, Popconfirm, Table, Tooltip, Typography} from "antd";
import {EventDTO, ExistsDto} from "./types";
import {ColumnsType} from "antd/es/table";
import AddButton from "../commons/AddButton";
import moment from "moment";
import {useForm} from "antd/lib/form/Form";
import TextArea from "antd/es/input/TextArea";
import {defaultFormItemLayout} from "../commons/forms";
import dayjs from "dayjs";
import {Link} from "react-router-dom";

export const EventsEditor: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [form] = useForm<EventDTO | undefined>(undefined);
    const [editableEvent, setEditableEvent] = useState<EventDTO | undefined>(undefined);
    const {isLoading, data, refetch} = useQuery<any,any,EventDTO[],any>('lodge-admin-events',
        () => axios.get(`/api/admin/events`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data), {
            enabled: (!!getAuth() && !!getAuth().token)
        }
    )

    useEffect(() => {
        if (!!editableEvent) {
            form.setFieldsValue({...editableEvent, startsAt: dayjs(editableEvent.startsAt)});
        }
    }, [editableEvent, form]);

    const submitChanges = useCallback((data: EventDTO) => {
        const toSend = {...data, ...{
            startsAt: data.startsAt.valueOf()
        }}
        const request = (!editableEvent)
            ? () => axios.post(`/api/admin/events`, toSend, {
                headers: {...getAuthHeaders()}
            })
            : () => axios.put(`/api/admin/events/${editableEvent?.id}`, toSend, {headers: {...getAuthHeaders()}});
        request()
            .then(() => {
                setEditableEvent(undefined);
                form.resetFields();
                setIsModalOpen(false);
                message.success('Job done!');
                refetch();
            })
            .catch(() => {
               message.error('Failed to complete work, please try again.');
               setEditableEvent(undefined);
            });
    }, [form, editableEvent, setEditableEvent, refetch]);

    const editEvent = useCallback((event: EventDTO) => {
        setEditableEvent(event);
        setIsModalOpen(true);
    }, [setEditableEvent, setIsModalOpen]);

    const cancelEvent = useCallback((event: EventDTO) => {
        axios.delete(`/api/admin/events/${event.id}`, {
            headers: {...getAuthHeaders()}
        })
        .then(() => {
            message.success('Event canceled!');
            refetch();
        })
        .catch(() => {
            message.error('Could not cancel. Please try again or refresh.')
        })
    }, [refetch]);

    const createNewEvent = useCallback(() => {
        setEditableEvent(undefined);
        setIsModalOpen(true);
    }, [setEditableEvent, setIsModalOpen]);

    const downloadAttendanceSheet = useCallback((event: EventDTO) => {
        axios.get(`/api/admin/reservations/${event.id}/attendance-sheet`, {
            responseType: "blob",
            headers: {...getAuthHeaders()}
        })
            .then(r => {
                const fileName = r.headers['x-reservation-file-id'] || `${event.title}-attendance-sheet.pdf`;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(r.data);
                link.download=fileName;
                link.click();
            })
            .catch(e => {
                const messsage = e?.response?.status === 400
                    ? e?.message || 'Something went wrong, please try again later or contact RPG Lodge directly!'
                    : 'Something went wrong, please try again later or contact RPG Lodge directly!';
                Modal.error({
                    title: 'Attendance sheet cannot be downloaded.',
                    content: messsage
                });
            })
    }, []);

    const validateTag = useCallback((tag: string, existingId: string | undefined) => {
        if (!tag) {
            return false;
        }
        return axios.get<object, AxiosResponse<ExistsDto>>(`/api/admin/events/exists/${tag}`, {
            headers: {
                ...getAuthHeaders()
            }
        })
        .then((r) => {
          const response = r.data;
          return !response.exists ||
              response.exists && !!existingId && existingId === response.entityId;
        })
    }, [getAuthHeaders])

    const columns = useMemo<ColumnsType<EventDTO>>(() => {
        return [
            {
                title: 'Title',
                dataIndex: 'title',
                width: 214
            }, {
                title: 'Description',
                dataIndex: 'description',
                width: 214,
                render: (value) => (
                    <Tooltip title={value}>
                        <Typography.Text style={{width: '50px'}} ellipsis={true}>{value}</Typography.Text>
                    </Tooltip>
                )
            }, {
                title: 'Location',
                dataIndex: 'location',
                width: 214
            }, {
                title: 'Navigation Link',
                dataIndex: 'navigationLink',
                width: 214,
                render: (value) => !!value ? <a href={value}>Navigation link</a> : <></>
            }, {
                title: 'Starts at',
                dataIndex: 'startsAt',
                width: 214,
                render: (value) => <Typography.Text>{moment(value).format('YYYY-MM-DD HH:mm')}</Typography.Text>
            }, {
                title: 'Event Media',
                dataIndex: 'mediaUrl',
                width: 214,
                render: (value) => !!value ? <><Image src={value} alt={'event media'} /></> : <></>
            }, {
                title: 'Reservation Link',
                width: 214,
                render: (_, record) => <Link to={`/reserve/${record.tag}`}>Reservation Link</Link>
            }, {
                title: <AddButton onSave={createNewEvent} />,
                width: '200px',
                render: (_, record) => (
                    <>
                        <Button type={"link"} onClick={() => editEvent(record)}>Edit</Button>
                        <Link style={{color: 'green'}} to={`/admin/tables/${record.id}`}>Tables</Link>
                        <Button type={"link"} onClick={() => downloadAttendanceSheet(record)}>Attendance Sheet</Button>
                        <Popconfirm
                            title={`Are you sure you want to cancel ${record.title} completely?`}
                            onConfirm={() => {
                            cancelEvent(record);
                        }}>
                            <Button type={"link"} style={{color: 'red'}} >Cancel</Button>
                        </Popconfirm>
                    </>
                )
            }
        ]
    }, [createNewEvent, cancelEvent, editEvent]);

    return (
      <>
          <Modal
              destroyOnClose={true}
              open={isModalOpen}
              closable={false}
              onCancel={() => {
                  form.resetFields();
                  setIsModalOpen(false);
              }}
              onOk={form.submit}>
              <Typography.Title level={3}>Event Editor</Typography.Title>
              <p>
                  <Typography.Text>Please enter the details below:</Typography.Text>
              </p>
              <Form form={form} {...defaultFormItemLayout} onFinish={submitChanges}>
                  <Form.Item
                      name="title"
                      label={"Title"}
                      required={true}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="tag"
                      label={"Tag"}
                      required={true}
                      hasFeedback={true}
                      validateDebounce={1000}
                      rules={[
                          {
                              validator: async (rule, value) => {
                                  const isValid = await validateTag(value, editableEvent?.id);
                                  if (isValid) {
                                      return Promise.resolve();
                                  } else {
                                      return Promise.reject(new Error('Tag already exists.'));
                                  }

                              }
                          },
                          {
                              pattern: new RegExp(/^[a-zA-Z0-9-]+$/),
                              message: 'invalid tag'
                          },
                          {min:8},
                          {max:12},
                          {required: true}
                      ]}
                  >
                      <Input placeholder={"Use only letters, numbers and the '-' symbol."} max={12} min={5} />
                  </Form.Item>
                  <Form.Item
                      name="startsAt"
                      label={"When"}
                      required={true}
                  >
                      <DatePicker showTime={true} />
                  </Form.Item>
                  <Form.Item
                      name="location"
                      label={"Location"}
                      required={true}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="navigationLink"
                      label={"Navigation Link"}
                      required={false}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="description"
                      label={"Description"}
                      required={false}
                  >
                      <TextArea maxLength={500} />
                  </Form.Item>
                  <Form.Item
                        name="mediaUrl"
                        label="Media (URL)"
                        required={false}
                  >
                      <Input />
                  </Form.Item>
              </Form>
          </Modal>
          <Table bordered columns={columns} loading={isLoading} dataSource={(data as any[])} />
      </>
    );
}