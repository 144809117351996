import {Button} from "antd";

export interface SaveButtonProps {
    onSave: () => void;
    disabled?: boolean;
}

const AddButton: React.FC<SaveButtonProps> = (props) => {
    const { onSave, disabled } = props;

    return (
        <Button type={"primary"}  onClick={onSave} block disabled={disabled} style={{fontSize: "large"}}>
            +
        </Button>
    );
};

export default AddButton;