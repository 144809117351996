import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {Guarded} from "../admin/Guarded";
import {Button, Form, Image, Input, InputNumber, Layout, message, Modal, Table, Tooltip, Typography} from "antd";
import {defaultFormItemLayout} from "../commons/forms";
import TextArea from "antd/es/input/TextArea";
import {useForm} from "antd/lib/form/Form";
import {useQuery} from "react-query";
import {getAuth, getAuthHeaders} from "../admin/auth";
import {ColumnsType} from "antd/es/table";
import AddButton from "../commons/AddButton";
import {TableDTO} from "./types";
import axios from "axios";
import { Popconfirm } from 'antd/lib';

export const TablesEditor: React.FC = () => {
    const { event } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [form] = useForm<TableDTO | undefined>(undefined);
    const [editableTable, setEditableTable] = useState<TableDTO | undefined>(undefined);
    const {isLoading, data, refetch} = useQuery<any,any,TableDTO[],any>('lodge-admin-events',
        () => axios.get(`/api/admin/tables/${event}`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data), {
            enabled: (!!getAuth() && !!getAuth().token)
        }
    )

    useEffect(() => {
        if (!!editableTable) {
            form.setFieldsValue({...editableTable});
        }
    }, [editableTable, form]);

    const submitChanges = useCallback((data: TableDTO) => {

        const request = (!editableTable)
            ? () => axios.post(`/api/admin/tables/${event}`, data, {
                headers: {...getAuthHeaders()}
            })
            : () => axios.put(`/api/admin/tables/${editableTable?.id}`, data, {headers: {...getAuthHeaders()}});
        request()
            .then(() => {
                setEditableTable(undefined);
                form.resetFields();
                setIsModalOpen(false);
                message.success('Job done!');
                refetch();
            })
            .catch(() => {
                message.error('Failed to complete work, please try again.');
                setEditableTable(undefined);
            });
    }, [event, form, editableTable, setEditableTable, refetch]);

    const editTable = useCallback((table: TableDTO) => {
        setEditableTable(table);
        setIsModalOpen(true);
    }, [setEditableTable, setIsModalOpen]);

    const createNewTable = useCallback(() => {
        setEditableTable(undefined);
        setIsModalOpen(true);
    }, [setEditableTable, setIsModalOpen]);

    const deleteTable = useCallback((tableId: string | undefined) => {
        axios.delete(`/api/admin/tables/${tableId}`, {
            headers: {...getAuthHeaders()}
        })
            .then(() => {
                message.success('Job done!');
                refetch();
            })
            .catch(() => {
                message.error('Failed to complete work, please try again.');
            });
    }, [refetch]);

    const columns = useMemo<ColumnsType<TableDTO>>(() => {
        return [
            {
                title: 'Title',
                dataIndex: 'title',
                width: 200
            }, {
                title: 'Description',
                dataIndex: 'description',
                width: 200,
                render: (value) => (
                    <Tooltip title={value}>
                        <Typography.Text style={{width: '50px'}} ellipsis={true}>{value}</Typography.Text>
                    </Tooltip>
                )
            }, {
                title: 'Game System',
                dataIndex: 'game',
                width: 200
            }, {
                title: 'Facilitator',
                dataIndex: 'facilitator',
                width: 200,
            }, {
                title: 'Seats min',
                dataIndex: 'seatsMin',
                width: 200,
            }, {
                title: 'Seats max',
                dataIndex: 'seatsMax',
                width: 200,
            }, {
                title: 'Media (URL)',
                dataIndex: 'mediaUrl',
                width: 200,
                render: (value) => !!value ? <Image src={value} alt={'table-image'}/> : <>N/A</>
            }, {
                title: 'Position',
                dataIndex: 'position',
                width: 200,
            }, {
                title: <AddButton onSave={createNewTable} />,
                width: '200px',
                render: (value, record) => (
                    <>
                        <Button type={"link"} onClick={() => editTable(record)}>Edit</Button>
                        <Popconfirm
                            title="Delete table and all reservations"
                            description="Are you sure to delete this table and all reservations on it?"
                            onConfirm={() => deleteTable(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type={"link"} danger>Delete</Button>
                        </Popconfirm>
                    </>
                )
            }
        ]
    }, [createNewTable, editTable]);


    return (
        <Guarded>
            <Layout style={{padding: '2rem', maxHeight: '100vh'}}>
                <Button style={{marginBottom: '1rem'}} onClick={() => navigate('/admin')}>Go back to administration.</Button>
                <Table bordered columns={columns} loading={isLoading} dataSource={(data as any[])} />
                <Modal
                    destroyOnClose={true}
                    open={isModalOpen}
                    closable={false}
                    onCancel={() => {
                        form.resetFields();
                        setIsModalOpen(false);
                    }}
                    onOk={form.submit}>
                    <Typography.Title level={3}>Table Editor</Typography.Title>
                    <p>
                        <Typography.Text>Please enter the details below:</Typography.Text>
                    </p>
                    <Form form={form} {...defaultFormItemLayout} onFinish={submitChanges}>
                        <Form.Item
                            name="title"
                            label={"Title"}
                            required={true}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label={"Description"}
                            required={false}
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            name="game"
                            label={"Game System"}
                            required={true}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="facilitator"
                            label={"Facilitator"}
                            required={true}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="seatsMin"
                            label={"Min Players"}
                            required={true}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="seatsMax"
                            label={"Max Players"}
                            required={true}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="position"
                            label={"Position"}
                            required={false}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="mediaUrl"
                            label="Media (URL)"
                            required={false}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout>
        </Guarded>
    )
}