import React, {useCallback, useMemo, useState} from 'react'
import {PublicTableDTO} from "../home/types";
import {Button, Card, Divider, Image, Progress, Typography} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import {Label} from "../commons/translations/Label";
import {useTranslation} from "react-i18next";
import {ReservationButton} from "./ReservationButton";

export type TableCardProps = {
    table: PublicTableDTO;
}

export const TableCard: React.FC<TableCardProps> = (props) => {
    const {t} = useTranslation();
    const {table} = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const toggleExpand = useCallback(() => {setExpanded(!expanded)}, [expanded, setExpanded]);
    const canAcceptReservations = useMemo<boolean>(() => table.reserved < table.seatsMax, [table.reserved, table.seatsMax])

    const progress = useMemo<number>(() => Math.floor((table.reserved/table.seatsMax) * 100), [table.reserved, table.seatsMax]);

    return (
        <Card title={table.game} style={{margin: '3px', borderColor: '#b8b8b5'}} key={table.id} >
            <>
                <Typography.Text strong>{table.title}</Typography.Text>
                <br/>
                {!!table.mediaUrl &&
                    <>
                        <Image src={table.mediaUrl} alt={'session media'} style={{height: '20em'}} />
                        <br/>
                    </>
                }
                <Label trl={'reservation.card.hosted.by'} />
                <Typography.Text italic >{' ' + table.facilitator}</Typography.Text>
                <br/>
                <Paragraph
                    ellipsis={expanded ? false : {
                        rows: 1,
                    }}
                >
                    {table.description}
                </Paragraph>
                <Button type={"link"} onClick={toggleExpand}>{expanded ? t('reservation.card.show.less') : t('reservation.card.learn.more')}</Button>
                <Divider type={"horizontal"} />
                <div>
                    <Progress percent={progress} steps={table.seatsMax} showInfo={false} />
                    <br/>
                    {`${table.reserved} / ${table.seatsMax} `}
                    <Label trl={'reservation.card.seats.reserved'} />
                </div>
                <br/>
                {!canAcceptReservations && <Label strong trl={'reservation.card.seats.full'} />}
                {canAcceptReservations && <ReservationButton table={table} />}
            </>
        </Card>
    );
}