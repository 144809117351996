import React, {useCallback, useMemo, useState} from 'react';
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {Button, Modal, Popconfirm, Select, Typography} from "antd";
import {PublicEventDTO} from "../home/types";
import {ReservationDTO} from "./types";
import {ColumnsType} from "antd/lib/table";
import {Divider, Table} from "antd/lib";
import {getAuthHeaders} from "../admin/auth";
import moment from "moment";

export const Reservations: React.FC = (props) => {

    const [event, setEvent] = useState<string | undefined>(undefined);
    const {isLoading: isLoadingEvents, data: upcoming} = useQuery(`upcoming-events`,
        () => axios.get<any,AxiosResponse<PublicEventDTO[]>>(`/api/events` ).then(r => r.data));
    const {isLoading: isLoadingReservations, data: reservations, refetch} = useQuery(`event-reservations-${event}`,
        () => axios.get<any,AxiosResponse<ReservationDTO[]>>(`/api/admin/reservations/${event}`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data), {
            enabled: !!event
        })
    const cancelReservation = useCallback((reservationId: string) => {
        axios.delete(`/api/admin/reservations/${reservationId}`, {
            headers: {...getAuthHeaders()}
        })
            .then(() => refetch())
            .catch(() => Modal.error({
                title: 'Error',
                content: 'We could not cancel this reservation, please try again later.'
            }));
    }, [refetch])


    const columns = useMemo<ColumnsType<ReservationDTO>>(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                width: 300
            },{
                title: 'Seats',
                dataIndex: 'seats',
                width: 300
            },{
                title: 'Code',
                dataIndex: 'code',
                width: 300
            },{
                title: 'Table',
                dataIndex: 'table',
                width: 300
            },{
                title: 'Game',
                dataIndex: 'game',
                width: 300
            },{
                title: 'Reserved at',
                dataIndex: 'reservedAt',
                width: 300,
                render: (value) => <Typography.Text>{moment(value).format('YYYY-MM-DD HH:mm')}</Typography.Text>
            }, {
                title: <></>,
                width: '200px',
                render: (value, record) => (
                    <>
                        <Popconfirm
                            title={`Are you sure you want to cancel ${record.seats} reserved seats by ${record.name}?`}
                            onConfirm={() => {
                                cancelReservation(record.id);
                            }}>
                            <Button type={"link"} style={{color: 'red'}} >Cancel</Button>
                        </Popconfirm>
                    </>
                )
            }
        ]
    }, [event, reservations]);

    return (
        <>
            <Typography.Text>Please select an event</Typography.Text>
            <br/>
            <Select style={{width: '100%'}} onSelect={setEvent}>
                {(upcoming || []).map((evt: any) => <Select.Option value={evt.id}>{evt.title}</Select.Option>)}
            </Select>
            <Divider type={"horizontal"} />
            {!!event && <Table dataSource={(reservations as any[])} loading={isLoadingReservations} bordered columns={columns}  />}
        </>
    )
}